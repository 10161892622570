<template>
  <div class="favorite-list"></div>
  <CardShop v-bind:shops="searchResult" :title="cardTitle" />
  <GoTop />
</template>

<script>
import CardShop from "@/components/CardShop.vue";
import GoTop from "@/components/GoTop.vue";
import mgls from "@/js/ManageLocalStorage.js";

import axios from "axios";
import Request from "@/js/Api.js";

export default {
  name: "FavoriteList",
  components: {
    CardShop,
    GoTop
  },
  data: function() {
    return {
      searchConditions: [],
      searchResult: "default",
      cardTitle: "いいねしたお店"
    };
  },
  methods: {
    // fordebug
    getShop: function() {
      console.log("called /api/getshop");
      this.searchConditions = mgls.getLikedShopId();
      this.getShopWithConditions(this.searchConditions);
    },
    //TODO 将来的に別ファイルに移動したい...
    getShopWithConditions: function(conditions) {
      const conditionsWithComma = conditions.join(",");
      const requestUrl =
        Request.api.getShopWithId + "?id=" + conditionsWithComma;
      // リクエスト
      // console.log("requestUrl:" + requestUrl);
      axios.get(requestUrl).then(response => {
        this.searchResult = JSON.stringify(response.data);
      });
    }
  },
  created: function() {
    this.getShop();
  }
};
</script>


<style scoped>
</style>