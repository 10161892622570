<template>
  <div class="how-to-use-kokodo">
    <h1>This is an how-to-use-kokodo page</h1>
    <h1>This is an how-to-use-kokodo page</h1>
    <h1>This is an how-to-use-kokodo page</h1>
    <h1>This is an how-to-use-kokodo page</h1>
    <h1>This is an how-to-use-kokodo page</h1>
    <h1>This is an how-to-use-kokodo page</h1>
  </div>
</template>
